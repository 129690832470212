@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.image_mobile {
  display: none;
}

.services {
  padding: 90px 0;
  &Item {
    margin: 10px;
    padding: 50px;
    border-radius: 20px;
    background: #FFC252;
    &__content {
      position: relative;
      z-index: 1;
      h4 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 40px;
        max-width: 798px;
      }
    }
    button {
      padding: 11px 46px;
      z-index: 2;
      position: relative;
    }
  }
}
.top {
  display: flex;
  margin: 0 -10px;
  &__col {
    width: 40%;
    .servicesItem {
      padding: 80px 50px 50px 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: calc(100% - 20px);
      &__content {
        p {
          margin-bottom: 70px;
        }
        
      }
      &__image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    &-item {
      width: 50%;
      &:nth-child(1) {
        width: 100%;
        .servicesItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 72px 50px;
          background: $blue;

          &__content {
            width: calc(100% - 230px);
            max-width: 515px;
          }
          &__image {
            width: 217px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
            }
          }
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        .servicesItem {
          padding: 50px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          height: calc(100% - 20px);
        }
      }
      &:nth-child(2) {
        .servicesItem {
          background: $red;
        }
      }
      &:nth-child(3) {
        .servicesItem {
          background: $purple;
        }
      }
    }
  }
}
.bot {
  display: flex;
  margin: 0 -10px;
  &__col {
    &:nth-child(1) {
      width: 70%;
      .servicesItem {
        background: #0f0f0f;
        color: $white;
        position: relative;
        height: calc(100% - 20px);
        &__content {
          margin-bottom: 90px;
        }
        &__image {
          position: absolute;
          bottom: 30px;
          right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          max-width: 484px;
          img {
            width: 100%;
          }
        }
      }
    }
    &:nth-child(2) {
      width: 30%;
      .servicesItem {
        background: $green;
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .services {
    padding: 70px 0;
    &Item {
      padding: 50px 32px;
    }
  }
  .top {
    &__col {
      .servicesItem {
        padding: 50px 32px;
      }
    }
    &__wrap {
      &-item {
        &:nth-child(1) {
          .servicesItem {
            padding: 50px 32px;
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          .servicesItem {
            padding: 50px 32px;
          }
        }
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .services {
    &Item {
      padding: 32px !important;
      &__content {
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
  .top {
    &__col {
      .servicesItem {
        &__content {
          margin-bottom: 32px;
          p {
            margin-bottom: 32px;
          }
        }
      }
    }
    &__wrap {
      &-item {
        &:nth-child(1) {
          .servicesItem {
            &__content {
              width: calc(100% - 180px);
            }
            &__image {
              width: 160px;
            }
          }
        }
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .top {
    flex-direction: column;
    &__wrap {
      width: 100%;
    }
    &__col {
      width: 100%;
      .servicesItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        &__image {
          width: 320px;
        }
        &__content {
          width: calc(100% - 340px);
          margin: 0;
        }
      }
    }
  }
  .bot {
    &__col {
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(1) {
        width: 50%;
        .servicesItem {
          &__content {
            margin: 0;
          }
          &__image {
            //display: none;
          }
        }
      }
    }
  }
}
// 750px
@include sm-md {
  .services {
    &Item {
      &__content {
        p {
          margin-bottom: 60px;
        }
      }
    }
  }

  .top {
    &__wrap {
      &-item {
        width: 100% !important;
      }
    }
    &__col {
      .servicesItem {
        &__image {
          display: none;
        }
        &__content {
          width: 100%;
        }
      }
    }
  }
  .bot {
    flex-wrap: wrap;
    &__col {
      width: 100% !important;
    }
  }
}
// 540px
@include xsm-sm {
  .image_mobile {
    display: block;
    margin: 10px;
    img {
      width: 100%;
    }
  }
  .services {
    &Item {
      padding: 30px 25px !important;
      &__content {
        width: 100% !important;
        p {
          margin-bottom: 40px !important;
        }
      }
      &__image {
        //display: none !important;
      }
    }
  }
}
