@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.heading {
  text-align: center;
  margin-bottom: 50px;
  h2 {
    margin-bottom: 15px;
  }
  p {
    color: $text-gray;
    max-width: 725px;
    margin: 0 auto;
    line-height: 28px;
  }
  &.smSpace {
    margin-bottom: 30px;
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .heading {
    margin-bottom: 40px;
  }
}
// 540px
@include xsm-sm {
  .heading {
    h2 {
      margin-bottom: 10px;
    }
  }
}
