@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.faq {
  padding: 90px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
    &-items {
      width: 50%;
    }
    &-content {
      width: calc(50% - 20px);
      max-width: 600px;
      background: $black;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 20px;
      h3 {
        text-align: center;
        color: $white;
        max-width: 500px;
        margin: 0 auto 50px auto;
      }
      button {
        padding: 11px 66px;
      }
      &-icon {
        width: 196px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 36px auto;
        img {
          width: 100%;
        }
      }
    }
  }
  &Item {
    margin-bottom: 25px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &__head {
      display: flex;
      position: relative;
      padding-right: 30px;
      @include transition;
      h6 {
        width: calc(100% - 60px);
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        background: $primary;
        color: $black;
        border-radius: 50%;
        position: relative;
        top: -4px;
        svg {
          width: 24px;
          height: 24px;
          @include transition;
        }
      }
      &.active {
        padding-bottom: 15px;
        button {
          svg {
            transform: rotate(45deg);
          }
        }
        span {
          svg {
            transform: rotate(180deg);
          }
        }
      }
      span {
        position: absolute;
        top: 4px;
        right: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
          @include transition;
        }
      }
    }
    &__body {
      overflow: hidden;
      padding-left: 60px;
      color: $text-gray;
      line-height: 25px;
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .faq {
    padding: 70px 0;
  }
}
// 1024px
@include lg-xlg {
  .faq {
    &__inner {
      flex-direction: column;
      &-items {
        width: 100%;
        margin: 0;
      }
      &-content {
        width: 100%;
        max-width: unset;
        margin-top: 40px;
        //display: none;
      }
    }
  }
}
// 540px
@include xsm-sm {
  .faq {
    &Item {
      &__body {
        padding-left: 0;
      }
      &__head {
        &.active {
          padding-bottom: 10px;
        }
        h6 {
          width: calc(100% - 50px);
        }
        button {
          width: 33px;
          height: 33px;
          margin-right: 17px;
          top: 0;
          svg {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }
}
