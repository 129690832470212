@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.portfolio {
  padding: 90px 0;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &Item {
    width: calc(33.3% - 20px);
    margin: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    min-height: 400px;
    &__content {
      position: absolute;
      bottom: -100%;
      left: 20px;
      width: calc(100% - 40px);
      background: #dfd7cb;
      padding: 20px 25px;
      border-radius: 20px;
      transition: 0.4s ease-in-out;
      opacity: 0;
      h5 {
        @include ellipsis(1);
      }
      p {
        @include ellipsis(1);
      }
    }
    &__image {
      position: relative;
      padding-bottom: 100%;
      border-radius: 20px;
      overflow: hidden;
      img {
        @include fullImage;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &:hover {
      .portfolioItem__content {
        bottom: 20px;
        opacity: 1;
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .portfolio {
    padding: 70px 0;
    &Item {
      min-height: unset;
    }
  }
}
// 1024px
@include lg-xlg {
  .portfolio {
    &Item {
      width: calc(50% - 20px);
      &__content {
        bottom: 20px;
        opacity: 1;
      }
    }
  }
}
// 750px
@include sm-md {
  .portfolio {
    &__inner {
      margin: 0;
    }
    &Item {
      width: 100%;
      margin: 0 0 20px 0;
      &:last-child {
        margin: 0;
      }
      &__content {
        padding: 20px;
      }
    }
  }
}
