@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.team {
  padding: 90px 0;
  &__inner {
    &-row {
      display: flex;
      margin: 0 -10px;
    }
  }
  &Item {
    width: calc(25% - 20px);
    margin: 0 10px;
    position: relative;
    &__content {
      left: 20px;
      bottom: 20px;
      width: calc(100% - 40px);
      background: #dfd7cb;
      position: absolute;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 10px 20px;
    }
    &__image {
      padding-bottom: 135%;
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        @include fullImage;
      }
    }
  }
  &Contact {
    width: calc(25% - 20px);
    margin: 0 10px;
    position: relative;
    border: 1px solid #d8cfc1;
    border-radius: 20px;
    &__inner {
      padding-bottom: 135%;
      position: relative;
      &-content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        width: 100%;
        height: 100%;
        text-align: center;
        h3 {
          margin-bottom: 42px;
        }
        button {
          padding: 11px 60px;
        }
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .team {
    padding: 70px 0;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .team {
    &Contact {
      display: none;
    }
    &Item {
      width: calc(33.3% - 20px);
    }
  }
}
// 930px
@include md-lg {
  .team {
    &__inner {
      &-row {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 750px
@include sm-md {
  .team {
    &Item {
      width: calc(100% - 20px);
      &__image {
        padding-bottom: 120%;
      }
    }
  }
}
