@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.footer {
  padding: 90px 0 0 0;
  color: $text-gray;
  &__inner {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #d8cfc1;
    &-col {
      width: calc(19% - 10px);
      &:first-child {
        width: calc(24% - 10px);
      }
      h6 {
        margin-bottom: 30px;
      }
      p {
        max-width: 248px;
        line-height: 28px;
      }
    }
    &-logo {
      width: 114px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
    &-link {
      display: block;
      @include transition;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: $text-gray;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: $primary;
      }
    }
    &-socials {
      display: flex;
      align-items: center;
      a {
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        background: $gray-light;
        border-radius: 5px;
        cursor: pointer;
        @include transition;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background: $primary;
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  &__copy {
    padding: 25px 0;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $text-gray;
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .footer {
    padding: 70px 0 0 0;
    &__inner {
      &-logo {
        margin-bottom: 24px;
      }
      &-col {
        h6 {
          margin-bottom: 24px;
        }
      }
      &-link {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .footer {
    &__inner {
      flex-wrap: wrap;
      &-col {
        width: calc(50% - 20px);
        margin: 15px 10px;
        p {
          max-width: 300px;
        }
        h6 {
          margin-bottom: 25px;
        }
        &:nth-child(1) {
          margin: 10px;
          width: calc(100% - 20px);
        }
      }
    }
  }
}
