@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.plans {
  padding: 90px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
    &-quote {
      width: calc(50% - 15px);
      background: $primary;
      padding: 50px;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 30px;
      h3 {
        margin-bottom: 20px;
      }
    }
    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-text {
        h6 {
          margin-bottom: 5px;
        }
      }
      &-avatar {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        img {
          @include fullImage;
        }
      }
    }
    &-item {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      &s {
        width: calc(50% - 15px);
        padding: 25px 0;
        h4 {
          margin-bottom: 10px;
        }
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .plans {
    padding: 70px 0;
    &__inner {
      &-quote {
        padding: 40px;
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .plans {
    &__inner {
      &-quote {
        padding: 32px;
      }
      &-items {
        padding: 0;
      }
      &-item {
        margin-bottom: 16px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .plans {
    &__inner {
      flex-direction: column;
      &-item {
        margin-bottom: 30px;
      }
      &-items {
        width: 100%;
      }
      &-quote {
        width: 100%;
        max-width: unset;
        margin-bottom: 50px;
        h3 {
          margin-bottom: 80px;
        }
      }
    }
  }
}
// 540px
@include xsm-sm {
  .plans {
    &__inner {
      &-quote {
        padding: 40px 25px;
      }
    }
  }
}
