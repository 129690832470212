@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.partners {
  padding: 90px 0;
  &__title {
    margin-bottom: 15px;
    text-align: center;
  }
  &__inner {
    border: 1px solid #d8cfc1;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    padding: 35px 10px;
  }
  &Item {
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include transition;
    &:hover {
      img {
        filter: brightness(0%);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @include transition;
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .partners {
    padding: 70px 0;
    &Item {
      width: 150px;
      height: 50px;
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .partners {
    &__inner {
      padding: 25px 16px;
    }
    &Item {
      width: 120px;
      height: 30px;
      &:nth-child(6) {
        height: 30px;
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .partners {
    &__inner {
      flex-wrap: wrap;
    }
    &Item {
      width: calc(33.3% - 20px);
      margin: 10px;
      height: 25px;
      &:nth-child(6) {
        height: 30px;
      }
    }
  }
}
// 540px
@include xsm-sm {
  .partners {
    &Item {
      width: calc(50% - 20px);
      max-width: 130px;
      height: 40px;
      &:nth-child(5) {
        max-width: 83px;
        height: 50px;
      }
      &:nth-child(6) {
        max-width: 83px;
        height: 50px;
      }
    }
  }
}
