@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.process {
  padding: 90px 0;
  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;
    button {
      margin: 0 10px;
      padding: 11px 30px;
      font-family: $mainFont;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      background: #d4ccbe;
      border-radius: 20px;
      @include transition;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.active {
        color: $white;
        background: $black;
      }
    }
  }
  &__inner {
    border-radius: 30px;
    background: #f2eadd;
    display: flex;
    justify-content: space-between;
    padding: 50px;
    &-content {
      width: calc(100% - 450px);
      max-width: 800px;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      h4 {
        margin-bottom: 15px;
      }
      p {
        color: $text-gray;
        margin-bottom: 24px;
      }
      &-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -7px -7px 20px -7px;
        p {
          margin: 7px;
          color: $black;
        }
        span {
          padding: 1px 10px;
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          text-transform: capitalize;
          color: #ffffff;
          margin: 7px;
          border-radius: 20px;
          &:nth-child(2) {
            background: $green;
          }
          &:nth-child(3) {
            background: $red;
          }
          &:nth-child(4) {
            background: $blue;
          }
        }
      }
    }
    &-image {
      box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      position: relative;
      width: 426px;
      overflow: hidden;
      min-height: 294px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
  }
  &__mob {
    display: none;
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .process {
    padding: 70px 0;
    &__inner {
      padding: 40px;
      &-content {
        margin-bottom: 16px;
      }
    }
    &__btns {
      button {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .process {
    &__inner {
      padding: 32px;
      &-content {
        width: calc(100% - 360px);
        padding: 0;
      }
      &-image {
        width: 340px;

      }
    }
  }
}
// 1024px
@include lg-xlg {
  .process {
    &__btns {
      button {
        font-size: 14px;
        line-height: 20px;
        padding: 11px 16px;
      }
    }
    &__inner {
      flex-direction: column;
      &-content {
        width: 100%;
        margin-bottom: 32px;

      }
      &-image {
        width: 100%;
     
      }
    }
  }
}
// 750px
@include sm-md {
  .process {
    &__btns {
      display: none;
    }
    &__mob {
      display: block;
      padding: 11px;
      text-align: center;
      border-radius: 50px;
      margin-bottom: 20px;
      background: #d4ccbe;
      &.active {
        background: $black;
        color: $white;
      }
    }
    &__inner {
      padding: 30px 25px;

      &-content {
        &-tags {
          p {
            width: calc(100% - 14px);
          }
        }
      }
    }
  }
}
