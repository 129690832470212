@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.button {
  padding: 11px 31px;
  font-family: $mainFont;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  cursor: pointer;
  @include transition;
  border-radius: 20px;
  &:hover {
    opacity: 0.8;
  }
  &.primary {
    color: $black;
    background: $primary;
    border: 1px solid $primary;
    &:hover {
      background: #ffd890;
      border: 1px solid $black;
      opacity: 1;
    }
  }
  &.uniq {
    color: $black;
    background: $primary;
    border: 1px solid $primary;
    &:hover {
      background: #ffd890;
      border: 1px solid #ffd890;
      opacity: 1;
    }
  }
  &.dark {
    color: $body-bg;
    background: $black;
    border: 1px solid $black;
    &:hover {
      background: $white;
      border: 1px solid $black;
      opacity: 1;
      color: $black;
    }
  }
  &.gray {
    padding: 11px 43px;
    background: #616161;
    color: $white;
    border: 1px solid #616161;
    &:hover {
      background: $white;
      border: 1px solid $white;
      opacity: 1;
      color: $black;
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .button {
    font-size: 16px;
    line-height: 22px;
  }
}

// 540px
@include xsm-sm {
  .button {
    font-size: 18px;
    line-height: 28px;
  }
}
