@import "./mixin.scss";
@import "./variables.scss";
html,
body {
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $text-gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $text-gray;
}
/*** 
====================================================================
Global Settings
====================================================================
   ***/
body {
  font-family: $mainFont;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: $black;
  background: $body-bg;
  &.active {
    overflow: hidden;
  }
}
a {
  text-decoration: none;
  opacity: 1;
  transition: 0.3s ease;
  color: $black;
  cursor: pointer;
}
ul {
  li {
    list-style: none;
  }
}
.auto__container {
  position: relative;
  max-width: 1668px;
  margin: 0 auto;
  padding: 0 100px;
}
.main {
  padding-top: 123px;
}

.anchor {
  position: relative;
  top: -90px;
  &#contact {
    top: -340px;
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .auto__container {
    max-width: 1240px;
    padding: 0 20px;
  }
  body {
    font-size: 16px;
    line-height: 22px;
  }
  .main {
    padding-top: 90px;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .auto__container {
    max-width: 1024px;
  }
}
// 1024px
@include lg-xlg {
  .main {
    padding-top: 92.72px;
  }
}
// 840px
@include md-lg-extra {
  .anchorLinks {
    &.active {
      background: transparent !important;
    }
    &:hover {
      background: transparent !important;
    }
  }
}
// 540px
@include xsm-sm {
  .auto__container {
    padding: 0 13px;
  }
  .main {
    padding-top: 87.59px;
  }
}
.anchorLinks {
  &.hidden {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  &.active {
    background: $primary;
  }
}
