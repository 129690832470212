@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 0;
  z-index: 100;
  &__inner {
    position: relative;
    z-index: 1;
    padding: 15px 40px;
    background: #f2eadd;
    border-radius: 50px;
    max-width: 880px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-logo {
      width: 114px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      @include transition;
      background: transparent !important;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
      }
    }
  }
}
.burger {
  display: none;
}
.nav {
  &__inner {
    display: flex;
    align-items: center;
    a {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-left: 20px;
      display: block;
      @include transition;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 50px;
      &:hover {
        background: $primary;
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .header {
    padding: 16px 0;
    &__inner {
      max-width: 820px;
      padding: 8px 24px;
    }
  }
  .nav {
    &__inner {
      a {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .header {
    &__inner {
      padding: 15px 25px;
    }
  }
  .nav {
    &__inner {
      a {
        margin-left: 10px;
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .nav {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    padding: 30px 25px;
    border-radius: 30px;
    background: #f2eadd;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.4s ease-in-out;
    &.active {
      max-height: 600px;
      opacity: 1;
    }
    &__inner {
      flex-direction: column;
      a {
        width: 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
   
      }
    }
  }
  .burger {
    display: block;
    cursor: pointer;
    @include transition;
    position: relative;
    width: 24px;
    height: 24px;
    &:hover {
      opacity: 0.8;
    }
    &::before {
      top: 16%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $black;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    span {
      top: 50%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $black;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      @include transform;
      @include transition;
    }
    &::after {
      bottom: 16%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $black;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    &.active {
      &::before {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      }
      span {
        opacity: 0;
      }
      &::after {
        bottom: 50%;
        -webkit-transform: translate(-50%, 50%) rotate(-45deg);
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
  }
}
// 540px
@include xsm-sm {
  .header {
    &__inner {
      &-logo {
        width: 95px;
      }
    }
  }
}
