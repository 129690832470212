@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.intro {
  padding: 120px 0 90px 0;
  h1 {
    position: relative;
    margin-bottom: 20px;
    z-index: 1;
    span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      z-index: -1;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  p {
    margin-bottom: 50px;
  }
  &__inner {
    text-align: center;
    max-width: 970px;
    margin: 0 auto;
    &-btns {
      max-width: 308px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        left: 100%;
        top: 100%;
        svg {
          width: 34px;
          height: 34px;
        }
      }
      button {
        padding: 11px;
        width: calc(50% - 10px);
      }
    }
  }
}
.aster {
  top: 40px;
  left: 13px;
  width: 44px;
  height: 59px;
}
.star {
  top: 68px;
  right: 32px;
  width: 60px;
  height: 60px;
}
// 1440px
@include xxxlg-xxxxlg {
  .intro {
    padding: 100px 0 70px 0;
    &__inner {
      max-width: 780px;
    }
  }
}
// 540px
@include xsm-sm {
  .intro {
    padding: 70px 0;
    h1 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 50px;
    }
  }
  .aster {
    width: 22px;
    height: 30px;
    left: 0;
  }
  .star {
    width: 32px;
    height: 32px;
    top: unset;
    bottom: 20px;
    right: 10px;
  }
}
