@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.contact {
  padding: 90px 0;
  &__inner {
    padding: 65px 40px;
    background: $gray-light;
    border-radius: 20px;
    &-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 476px;
      margin: 0 auto;
      button {
        padding: 11px;
        width: calc(50% - 10px);
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .contact {
    padding: 70px 0;
    &__inner {
      padding: 50px 32px;
      &-btns {
        max-width: 420px;
      }
    }
  }
}
// 540px
@include xsm-sm {
  .contact {
    &__inner {
      padding: 40px 20px;
      &-btns {
        flex-direction: column;
        max-width: unset;
        button {
          width: 100%;
          margin: 0 0 15px 0;
        }
      }
    }
  }
}
