h1 {
  font-weight: 800;
  font-size: 60px;
  line-height: 82px;
}
h2 {
  font-weight: 800;
  font-size: 45px;
  line-height: 61px;
}
h3 {
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
}
h4 {
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
}
h5 {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
}
h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  &.sm {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
  }
}
ul {
  li {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
}
// 1540px
@include xxxxlg-xxxxxlg {
  h3 {
    font-size: 30px;
    line-height: 40px;
  }
  h4 {
    font-size: 26px;
    line-height: 32px;
  }
}
// 1440px
@include xxxlg-xxxxlg {
  h1 {
    font-size: 50px;
    line-height: 70px;
  }
  h2 {
    font-size: 38px;
    line-height: 48px;
  }
  h3 {
    font-size: 30px;
    line-height: 40px;
  }
  h4 {
    font-size: 26px;
    line-height: 32px;
  }
  h5 {
    font-size: 22px;
    line-height: 30px;
  }
  h6 {
    font-size: 20px;
    line-height: 28px;
    &.sm {
      font-size: 14px;
      line-height: 24px;
    }
  }
  ul {
    li {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
// 540px
@include xsm-sm {
  h1 {
    font-size: 40px;
    line-height: 54px;
  }
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  h4 {
    font-size: 25px;
    line-height: 34px;
  }
  h5 {
    font-size: 25px;
    line-height: 34px;
  }
  h6 {
    font-size: 20px;
    line-height: 27px;
  }
  body {
    font-size: 18px;
    line-height: 26px;
  }
  ul {
    li {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
    }
  }
}
