@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.price {
  padding: 90px 0;
  &__btns {
    display: flex;
    max-width: 310px;
    margin: 0 auto 30px auto;
    justify-content: space-between;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #d8cfc1;
    button {
      width: calc(50% - 7px);
      padding: 11px;
      font-weight: 600;
      font-size: 18px;
      font-family: $mainFont;
      line-height: 28px;
      text-align: center;
      color: #000000;
      background: #d4ccbe;
      @include transition;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &.active {
        background: $black;
        color: $white;
      }
    }
  }
  &__inner {
    margin-bottom: 50px;
    &-row {
      display: flex;
      margin: 0 -10px;
    }
  }
  &Item {
    width: calc(25% - 20px);
    margin: 0 10px;
    background: #f2eadd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 30px 30px 30px;
    border-radius: 20px;
    &__content {
      margin-bottom: 60px;
      h4 {
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 24px;
        color: $text-gray;
      }
      h6.sm {
        margin-bottom: 3px;
      }
      ul {
        color: $text-gray;
        li {
          padding-left: 20px;
          position: relative;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            width: 10px;
            height: 10px;
            display: block;
            border-radius: 3px;
            background: $primary;
            position: absolute;
            top: 5px;
            content: "";
            left: 0;
          }
        }
      }
      &-price {
        display: flex;
        align-items: flex-end;
        font-weight: 700;
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 24px;
        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
    button {
      width: 100%;
    }
  }
}
.join {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  background: $black;
  border-radius: 30px;
  &__content {
    width: calc(100% - 450px);
    max-width: 800px;
    padding: 10px 0;
    h2 {
      color: $white;
      margin-bottom: 30px;
    }
    p {
      color: #c9c9c9;
      margin-bottom: 48px;
    }
  }
  &__image {
    background: #fdf7ed;
    box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    position: relative;
    width: 426px;
    min-height: 294px;
    img {
      width: 290px;
      position: absolute;
      left: 50%;
      @include transformX;
      bottom: 0;
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .price {
    padding: 70px 0;
    &__btns {
      button {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &Item {
      padding: 40px 32px;
      &__content {
        margin-bottom: 20px;
      }
    }
  }
  .join {
    padding: 40px;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .price {
    &__btns {
      margin-bottom: 20px;
    }
    &__inner {
      &-row {
        flex-wrap: wrap;
      }
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
  .join {
    padding: 32px;
    align-items: center;
    &__content {
      width: calc(100% - 360px);
      padding: 0;
    }
    &__image {
      width: 340px;
      min-height: 340px;
      img {
        max-width: 280px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .join {
    flex-direction: column;

    &__content {
      width: 100%;
      margin-bottom: 70px;
      max-width: unset;
    }
    &__image {
      width: 100%;
      img {
        max-width: 340px;
      }
    }
  }
}
// 750px
@include sm-md {
  .price {
    &__inner {
      &-row {
        margin: 0;
      }
    }
    &Item {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 35px 25px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .join {
    padding: 40px 25px;
    &__image {
      min-height: 240px;
      img {
        max-width: 240px;
      }
    }
  }
}
